import { useAbility } from "@casl/react";
import { Card, CardContent, CircularProgress, Grid, Link, Typography } from "@mui/material";
import { DataGridPro, GridActionsCellItem, GridColDef } from "@mui/x-data-grid-pro";
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import dayjsDuration from "dayjs/plugin/duration";
import { FC, useMemo, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import useGetSessions from "src/hooks/useGetSessions";
import useTerminateSession from "src/hooks/useTerminateSession";
import { AbilityContext } from "src/services/permissions/context";
import { SessionsViewProps } from "./interfaces";

dayjs.extend(dayjsDuration);

function calculateSessionDuration(intl: IntlShape, startTime: string, endTime?: string): string {
  const startDate = dayjs(startTime);
  const endDate = endTime ? dayjs(endTime) : dayjs(); // If endTime is null, use current time
  const duration = endDate.diff(startDate);

  const durationObj = dayjs.duration(duration);
  const hours = durationObj.hours();
  const minutes = durationObj.minutes();

  return intl.formatMessage({ id: "sessionDetails_durationXyz" }, { hours, minutes });
}

const SessionsTable: FC<SessionsViewProps> = ({ filter }) => {
  const intl = useIntl();
  const ability = useAbility(AbilityContext);
  const navigate = useNavigate();

  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs().subtract(6, "day").startOf("day"),
    dayjs().endOf("day"),
  ]);

  const { data, isLoading, isFetching, fetchNextPage } = useGetSessions({
    ...filter,
    startTimeFrom: dateRange[0]?.startOf("day").toISOString(),
    startTimeTo: dateRange[1]?.endOf("day").toISOString(),
  });
  const { mutate } = useTerminateSession(intl);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "startTime",
        headerName: intl.formatMessage({ id: "timeRange_startTimeLabel" }),
        sortable: false,
        minWidth: 175,
        valueFormatter: (value) => dayjs(value).format("L LT"),
      },
      {
        field: "locationName",
        headerName: intl.formatMessage({ id: "location_label" }),
        sortable: false,
        renderCell: (params) => (
          <Link underline="hover" href={`/locations/${params.row.locationId}`}>
            {params.value}
          </Link>
        ),
      },
      {
        field: "ocppStationId",
        headerName: intl.formatMessage({ id: "station_ocppIdLabel" }),
        sortable: false,
        minWidth: 175,
      },
      {
        field: "initiatedBy",
        headerName: intl.formatMessage({ id: "sessionDetails_initiatedByLabel" }),
        sortable: false,
      },
      {
        field: "wattHour",
        headerName: intl.formatMessage({ id: "session_energyConsumedUnit" }),
        sortable: false,
        headerAlign: "right",
        align: "right",
        valueFormatter: (value) => value / 1000,
      },
      {
        field: "tariffName",
        headerName: intl.formatMessage({ id: "tariff_label" }),
        sortable: false,
        valueGetter: (value, row) => {
          if (row.tariffId) return row.tariffName;
          return undefined;
        },
      },
      {
        field: "cost",
        headerName: intl.formatMessage({ id: "sessionDetails_costLabel" }),
        sortable: false,
        headerAlign: "right",
        align: "right",
        valueGetter: (value, row) => {
          if (row.tariffId)
            return intl.formatNumber(parseFloat(row.cost), {
              style: "currency",
              currency: row.currency,
              currencyDisplay: "narrowSymbol",
            });
          return undefined;
        },
      },
      {
        field: "duration",
        headerName: intl.formatMessage({ id: "sessionDetails_durationLabel" }),
        sortable: false,
        valueGetter: (value, row) => calculateSessionDuration(intl, row.startTime, row.endTime),
      },
      {
        field: "status",
        headerName: intl.formatMessage({ id: "generic_statusLabel" }),
        sortable: false,
      },
      {
        field: "actions",
        type: "actions",
        width: 20,
        getActions: (params) => {
          if (params.row.status === "ACTIVE")
            return [
              <GridActionsCellItem
                key={params.id}
                label={intl.formatMessage({ id: "sessionAction_terminate" })}
                onClick={() => mutate(params.id.toString())}
                showInMenu
              />,
            ];
          return [];
        },
      },
    ],
    [intl, mutate],
  );

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <FormattedMessage id="session_pageHeader" />
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <DateRangePicker value={dateRange} onChange={(range) => setDateRange(range)} />
          </Grid>

          <Grid item xs={12} maxHeight="70vh" minHeight={100}>
            {isLoading ? (
              <center>
                <CircularProgress />
              </center>
            ) : (
              <DataGridPro
                autosizeOnMount
                autosizeOptions={{
                  expand: true,
                  includeOutliers: true,
                }}
                hideFooter
                disableColumnMenu
                disableColumnReorder
                loading={isFetching}
                columns={columns}
                rows={data?.pages.flatMap((page) => page.data.items.map((item) => item)) ?? []}
                slotProps={{
                  loadingOverlay: {
                    variant: "linear-progress",
                    noRowsVariant: "linear-progress",
                  },
                }}
                onRowsScrollEnd={() => fetchNextPage()}
                onCellClick={(params) => {
                  if (params.field === "locationName") navigate(`/locations/${params.row.locationId}`);
                }}
                onRowClick={(params) => {
                  navigate(`/sessions/${params.id}`);
                }}
                columnVisibilityModel={{
                  tariffName: ability.can("read", "Tariff"),
                  cost: ability.can("read", "Tariff"),
                  actions: ability.can("control", "Session"),
                }}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SessionsTable;
